.input
{
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: center;
border: 1px solid #fff;
background-color: #fff;
border-radius: 8px;
min-width: 300px;
margin:  10px auto;
padding: 5px 7px;

}
.btn
{
border: 1px solid #fff;
background-color: #fff;
border-radius: 8px;
margin:  10px auto;
}
.mainblock
{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 50px;
  margin-right: 30px;
    border-radius: 8px;
    background-color: #108006;
    margin:30px 10px;
    
}
.header
{
    color:#fff;
    font-weight: bold;text-align: center;
   margin-top: 20px;
}