@media (min-width: 650px) {
  .GridBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.Link {
  text-decoration: none;
  color: #108006;
  font-weight: 600;
}
.Link:hover {
  color: black;
}
.GridBlock_element {
  max-width: 100px;
  margin: 10px 20px;
}
